import { ProfileModel } from '@models/profile.model';
import { PremiumAdStatusEnum } from '@models/premium-ad.model';
import { ProfileMainCategoryEnum } from '@models/profile-form-data.model';

export class PremiumServiceListModel {
  accountId: number;
  adId: string | null;
  amount: number;
  bannerImageId: string | null;
  bannerMobileImageId: string | null;
  banner_url: string | null;
  createdBy: number;
  currency: ServicesCurrency;
  dateActivation: Date;
  dateExpiration: Date;
  discountAppliedBy: number | null;
  discountDateApplied: Date | null;
  discountId: number | null;
  discountPercentage: number | null;
  discountReasonId: number | null;
  discountType: ServicesDiscountType | null;
  exchangeRate: number;
  family: string;
  finalAmount: number;
  id: number;
  inventoryId: number;
  isBanner: 0 | 1;
  isCreatedByForced: 0 | 1;
  isRecurring: 0 | 1;
  listPrice: number;
  locationId: number;
  name: string;
  orderId: number;
  principalCategory: ProfileMainCategoryEnum;
  runDateEnd: Date | string;
  runDateStart: Date | string;
  secondaryCategory: string | null;
  status: PremiumAdStatusEnum;
  systemAmount: number;
  systemFinalAmount: number;
  systemListPrice: number;
  pendingBanner: IPendingBanner;
  isWorldwide?: boolean;
  location?: {
    path: string;
    timezone: string;
  };
  renewedFrom: number;
  dateActivationConverted?: string;
  dateExpirationConverted?: string;
  dateRevealConverted?: string;
  dateExpirationTime?: string;
  expiresIn?: number;
  notAssigned?: boolean;
  isRenewable?: boolean;
  inCart?: boolean;
  isRealDurationVisible?: boolean;
  realDuration?: number;
  isOldLocation?: boolean;
  locationFormatted?: string;
  locationName?: string;
  pseudoStatus?: PseudoStatus;
  pseudoStatusDescription?: PseudoStatusDescription;

  profile?: ProfileModel;
  indexedText?: string;
  isPremiumService?: boolean;
  isPremiumAd?: boolean;
  hasUnapprovedBanner?: boolean;

  static fromJSON(json: any): PremiumServiceListModel {
    return {
      accountId: json.account_id,
      adId: json.ad_id || null,
      amount: json.amount,
      bannerImageId: json.banner_image_id || null,
      bannerMobileImageId: json.banner_mobile_image_id || null,
      banner_url: json.banner_url || null,
      createdBy: json.created_by,
      currency: json.currency,
      dateActivation: json.date_activation,
      dateExpiration: json.date_expiration,
      discountAppliedBy: json.discount_applied_by || null,
      discountDateApplied: json.discount_date_applied
        ? new Date(json.discount_date_applied)
        : null,
      discountId: json.discount_id || null,
      discountPercentage: json.discount_percentage || null,
      discountReasonId: json.discount_reason_id || null,
      discountType: json.discount_type || null,
      exchangeRate: json.exchange_rate,
      family: json.family,
      finalAmount: json.final_amount,
      id: json.id,
      inventoryId: json.inventory_id,
      isBanner: json.is_banner,
      isCreatedByForced: json.is_created_by_forced,
      isRecurring: json.is_recurring,
      listPrice: json.list_price,
      locationId: json.location_id,
      name: json.name,
      orderId: json.order_id,
      principalCategory: json.principal_category,
      runDateEnd: json.run_date_end ? new Date(json.run_date_end) : null,
      runDateStart: json.run_date_start ? new Date(json.run_date_start) : null,
      secondaryCategory: json.secondary_category || null,
      status: json.status,
      systemAmount: json.system_amount,
      systemFinalAmount: json.system_final_amount,
      systemListPrice: json.system_list_price,
      pendingBanner: json.pending_banner,
      renewedFrom: json.renewed_from,
      hasUnapprovedBanner: true
    };
  }
}

export interface IPendingBanner {
  ad_id: string;
  image_id: string;
  mobile_image_id: string;
  status: string;
  url: string;
}

export enum ServicesCurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD'
}

export enum ServicesDiscountType {
  Auto = 'auto',
  PromoCode = 'promo code',
  Custom = 'custom'
}

export class CreditCardsListModel {
  id: number;
  accountId: number;
  holderName: string;
  type: string;
  address: string;
  lastFourDigits: string;
  expireMonth: Date;
  expireYear: Date;
  isAutoBill: boolean;
  token: string;
  gateway: string;

  static fromJSON(json: any): CreditCardsListModel {
    return {
      id: json.id,
      accountId: json.account_id,
      holderName: json.holder_name,
      type: json.type,
      address: json.address,
      lastFourDigits: json.last_four_digits,
      expireMonth: new Date(json.expire_month),
      expireYear: new Date(json.expire_year),
      isAutoBill: json.is_auto_bill,
      token: json.token,
      gateway: json.gateway
    };
  }
}

export enum PseudoStatus {
  Online = 'online',
  Offline = 'offline',
  Active = 'active',
  Pending = 'pending',
  Future = 'future',
  PaidNotOnline = 'paidNotOnline',
  PaidHidden = 'paidHidden'
}

export enum PseudoStatusDescription {
  OrderReturned = 'order returned',
  OrderUnderReview = 'order under review',
  NotSubmittedChanges = 'not submitted changes'
}

export interface IPremiumAdPrice {
  base: number;
  basicDuration: number;
  bump: number;
  duration: number;
  features: IPremiumAdPriceItems[];
  identifier: string;
  items: IPremiumAdPriceItems[];
  total: number;
}

export interface IPremiumAdPriceItems {
  name: string;
  price: number;
  paid?: number | boolean;
}

export type PremiumAdPriceReqPayload = {
  location_id: number;
  inventory_id: number;
  main_categories: string[];
  location_categories: string[];
  specialty_categories: string[];
  is_vip: number;
  is_animated_thumbnail: number;
  is_group_ad: number;
  identifier: string;
  ad_id: string;
};

export class EligableDateModel {
  isActive: boolean;
  isForced: boolean;
  last: Date | string;
  next: Date | string;

  static fromJSON(json: any): EligableDateModel {
    return {
      isActive: json.is_active,
      isForced: json.is_forced,
      last: new Date(json.last),
      next: new Date(json.next)
    };
  }
}

export interface ILocationPresentationCategoriesCountResponse {
  location_id: number;
  presentation_category_id: number;
  count: number;
}

export interface ILocationPresentationCategoriesCountReqBody {
  location_id: number;
  main_category_id?: number;
  secondary_category_id?: number;
}

export enum RevokeRevisionErrorMsgCode {
  RevisionNotFound = 'REVISION_NOT_FOUND',
  AlreadyRevoked = 'ALREADY_REVOKED',
  NotRevokableAlreadyRevised = 'NOT_REVOKABLE_ALREADY_REVISED',
  ProfileAlreadyDeleted = 'PROFILE_ALREADY_DELETED',
  NoActivePremiumAd = 'NO_ACTIVE_PREMIUM_AD'
}

export type ChangePublishDateReqPayload = {
  premiumAdId: number;
  dateActivation: Date | string;
  accountId: number;
};

export type AdjustEndDateReqPayload = {
  premiumAdId: number;
  dateExpiration: Date | string;
  accountId: number;
};

export type CopyProfileOptions = {
  copyPhotos: boolean;
  personId?: string;
};

export type RenewPremiumServiceReqPayload = {
  accountId: number;
  duration: number;
  premiumServiceId: number;
};
