import { ProfileModel } from '@models/profile.model';
import {
  IPremiumAdPrice,
  PseudoStatus,
  PseudoStatusDescription,
  ServicesCurrency,
  ServicesDiscountType
} from '@app/pages/my/features/services/data-access/models/all-services.model';

export class PremiumAdModel {
  id: number;
  accountId: number;
  adId: string;
  amount: number;
  amountId: number;
  basicDuration: number;
  categories: string[];
  createdBy: number;
  currency: ServicesCurrency;
  dateActivation: Date;
  dateActivationPostponeAttempts: number;
  dateExpiration: Date;
  discountAppliedBy: any;
  discountDateApplied: Date | null;
  discountId: number;
  discountPercentage: number;
  discountReasonId: number;
  discountType: ServicesDiscountType;
  duration: number;
  exchangeRate: number;
  finalAmount: number;
  inventoryId: number;
  isAnimatedThumbnail: number;
  isGroupAd: number;
  isRecurring: 0 | 1;
  isRenew: number;
  isShortTermExtended: number;
  isVideo: number;
  isVip: number;
  locationId: number;
  orderDateCreated: Date;
  orderId: number;
  status: PremiumAdStatusEnum;
  systemAmount: number;
  systemFinalAmount: number;
  upgradedFrom: number;
  isWorldwide?: boolean;
  isOldLocation?: boolean;

  location?: {
    path: string;
    timezone: string;
  };
  dateActivationConverted?: string;
  dateExpirationConverted?: string;
  dateRevealConverted?: string;
  dateExpirationTime?: string;
  expiresIn?: number;
  isRenewable?: boolean;
  inCart?: boolean;
  isRealDurationVisible?: boolean;
  realDuration?: number;
  locationFormatted?: string;
  locationName?: string;
  pseudoStatus?: PseudoStatus;
  pseudoStatusDescription?: PseudoStatusDescription;

  profile?: ProfileModel;
  pricing?: IPremiumAdPrice;
  bump?: number;
  indexedText?: string;
  isPremiumAd?: boolean;
  isPremiumService?: boolean;

  static fromJson(json): PremiumAdModel {
    return {
      id: json.id,
      accountId: json.account_id,
      adId: json.ad_id,
      amount: json.amount,
      amountId: json.amount_id,
      basicDuration: json.basic_duration,
      categories: json.categories ?? [],
      createdBy: json.created_by,
      currency: json.currency,
      dateActivation: json.date_activation,
      dateActivationPostponeAttempts: json.date_activation_postpone_attemps,
      dateExpiration: json.date_expiration,
      discountAppliedBy: json.discount_applied_by,
      discountDateApplied: json.discount_date_applied
        ? new Date(json.discount_date_applied)
        : null,
      discountId: json.discount_id,
      discountPercentage: json.discount_percentage,
      discountReasonId: json.discount_reason_id,
      discountType: json.discount_type,
      duration: json.duration,
      exchangeRate: json.exchange_rate,
      finalAmount: json.final_amount,
      inventoryId: json.inventory_id,
      isAnimatedThumbnail: json.is_animated_thumbnail,
      isGroupAd: json.is_group_ad,
      isRecurring: json.is_recurring,
      isRenew: json.is_renew,
      isShortTermExtended: json.is_short_term_extended,
      isVideo: json.is_video,
      isVip: json.is_vip,
      locationId: json.location_id,
      orderDateCreated: new Date(json.order_date_created),
      orderId: json.order_id,
      status: json.status,
      systemAmount: json.system_amount,
      systemFinalAmount: json.system_final_amount,
      upgradedFrom: json.upgraded_from
    };
  }
}

export enum PremiumAdStatusEnum {
  Active = 'active',
  Pending = 'pending',
  Expired = 'expired',
  Canceled = 'canceled',
  Future = 'future',
  NA = 'n/a'
}
